import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axios';

const AxiosInterceptor = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 404 || error.response.status === 401 || error.response.status === 403) {
          navigate('/');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [navigate]);

  return null;
};

export default AxiosInterceptor;
