import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrateRoot(
    rootElement,
    <React.StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <Toaster position="top-center" reverseOrder={true}/>
          <App />
        </HelmetProvider>
      </Provider>
    </React.StrictMode>
  );
} else {
  createRoot(rootElement).render(
    <React.StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <Toaster position="top-center" reverseOrder={true}/>
          <App />
        </HelmetProvider>
      </Provider>
    </React.StrictMode>
  );
}

// Measure performance
reportWebVitals();
