import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/comman.scss";
import "./assets/scss/custom.scss";

import { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AxiosInterceptor from "./utils/AxiosInterceptor";

const Layout = lazy(() => import("./pages/Layout"));
const CompanyLayout = lazy(() => import("./pages/CompanyLayout"));
const HomePage = lazy(() => import("./pages/HomePage"));
const JobDetailPage = lazy(() => import("./pages/JobDetailPage"));
const JobFormPage = lazy(() => import("./pages/JobFormPage"));
const CompanyPage = lazy(() => import("./pages/CompanyPage"));
const Error404 = lazy(() => import("./pages/Error404"));
const ScrollToTop = lazy(() => import("./components/shared/ScrolltoTop"));
const Professionals = lazy(() => import("./Professionals/Professionals"));

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => setLoading(false);
    window.addEventListener("load", handleLoad);
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  const withLayout = (element, helmetProps) => (
    <>
      <Helmet {...helmetProps} />
      <Layout>{element}</Layout>
    </>
  );

  const withCompanyLayout = (element, helmetProps) => (
    <>
      <Helmet {...helmetProps} />
      <CompanyLayout>{element}</CompanyLayout>
    </>
  );

  return loading ? (
    <div className="center">
      <span className="loader"></span>
    </div>
  ) : (
    <HelmetProvider>
      <BrowserRouter>
        <ErrorBoundary>
          <Suspense>
            <ScrollToTop />
            <AxiosInterceptor />
            <Routes>
              <Route
                path="/"
                element={withLayout(<HomePage />, {
                  title: "Find Top Jobs by Disamina | Hiring Now",
                  description:
                    "Discover the best job opportunities at Disamina. Apply now for top roles in IT, Engineering, Marketing, and more. Start your career today!",
                  "og:title": "Find Top Jobs by Disamina | Hiring Now",
                  "og:description":
                    "Discover the best job opportunities at Disamina. Apply now for top roles in IT, Engineering, Marketing, and more. Start your career today!",
                  "og:url": "https://jobs.disamina.ai",
                })}
              />
              <Route
                path="/:clid/:customerName/:jobTitle/:jobCode"
                element={withCompanyLayout(<JobDetailPage />, {
                  title: "Job Details - Disamina",
                  description:
                    "View detailed information about this job opening at Disamina. Apply now!",
                })}
              />
              <Route
                path="/jobform"
                element={withLayout(<JobFormPage />, {
                  title: "Apply for a Job - Disamina",
                  description:
                    "Submit your application for a job at Disamina. Start your career today!",
                })}
              />
              <Route
                path="/:clid"
                element={withCompanyLayout(<CompanyPage />, {
                  title: "Company Profile - Disamina",
                  description:
                    "Learn more about this company and their job openings at Disamina.",
                })}
              />
              <Route
                path="/professional"
                element={withLayout(<Professionals />)
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;